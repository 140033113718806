import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"

const Blog = ({ data }) => {
  return (
    <Layout>
      <div className="blog-posts">
        <SEO title="Blog | Yojan Regmi" />

        {data.allMarkdownRemark.edges.map(post => (
          <div key={post.node.id} className="blog-post-front">
            <time datetime={post.node.frontmatter.date}>
              {post.node.frontmatter.date}
            </time>
            <h2><Link to={post.node.frontmatter.path}>{post.node.frontmatter.title}</Link></h2>
            <p className="blog-preview">{post.node.frontmatter.preview}</p>
            <Link to={post.node.frontmatter.path}>Read More</Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark {
      edges {
        node {
          html
          id
          frontmatter {
            path
            title
            date
            author
            preview
          }
        }
      }
    }
  }
`

export default Blog
